import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import DataTable from 'react-data-table-component';
import { useAuth0 } from "@auth0/auth0-react";
import Modal from 'react-modal';
import { useApi } from './useApi';
import Moment from 'moment';
import './index.css';

/* Modal styles and setup */
const modalStyles = {
    overlay: {
        background: "rgba(0, 0, 0, 0.5)",
        overflowY: ""
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: "850px"
    },
};

Modal.setAppElement('#root');

// Fetch Url for task logs.
export const LogFetcher = (props) => {
    const [currentTaskId, setCurrentTaskId] = React.useState(null);
    const {
        loading,
        error,
        refresh,
        data: logUrl,
    } = useApi(`https://api.treble.tech/api/Debug/tasks/${props.taskId}/log`);


    function handleFetchLogClick() {
        console.log("Refreshing log url");
        refresh();
    }

    if (currentTaskId === null) {
        setCurrentTaskId(props.taskId);
    } else if (currentTaskId !== props.taskId) {
        setCurrentTaskId(props.taskId);
        refresh();
    }

    if (!logUrl) {
        return (
            <div>
                <button onClick={() => handleFetchLogClick()}>Update Log url</button>
            </div>
        );
    }

    if (!logUrl.success) {
        return (
            <div>
                <button onClick={() => handleFetchLogClick()}>Fetch Log url</button>
                Unable to fetch log: {logUrl.message}
            </div>
        );
    }

    return (
        <div>
            <button onClick={() => handleFetchLogClick()}>Fetch Log url</button>
            <a href={logUrl.url} target="_blank">Download log</a>
            <button onClick={() => navigator.clipboard.writeText(logUrl.url)}>Copy log url</button>
        </div>
    )
}

/* Generate and render task run command for task. */
export const TaskRunCommand = (props) => {
    let command = "";
    if (props.task.taskType === "GA") {
        command = `> python3 ga_start.py \\\n\
        --jobid ${props.task.id} \\\n\
        --provider local \\\n\
        --reporter logging \\\n\
        --workdirectory /tmp/ga \\\n\
        --modelurl ${props.urls["ModelUrl"]} \\\n\
        --settingsurl ${props.urls["SettingsUrl"]} \\\n\
        --outputdataurl /tmp/ga/output_${props.task.id}.zip \\\n\
        --outputjsonurl /tmp/ga/output_${props.task.id}.json`;
    }
    else if (props.task.taskType === "DG") {
        command = `> python3 docker/wrapper/main.py ${props.task.id} \\\n\
        --dataprovider local \\\n\
        --reporter logging \\\n\
        --loglevel 20 \\\n\
        --datapath /tmp/data \\\n\
        --outputpath /tmp/output \\\n\
        --meshUrl ${props.urls["MeshUrl"]} \\\n\
        --settingsUrl ${props.urls["SettingsUrl"]} \\\n\
        --outputDataUrl /tmp/output/output_${props.task.id}.zip \\\n\
        --outputJsonUrl /tmp/output/output_${props.task.id}.json \\\n\
        --dacoustics /solver-dg-lp/solvers/acousticsdg \\\n\
        --solverpath /solver-dg-lp/solvers/acousticsdg`;
    }
    else if (props.task.taskType === "Mesher") {
        command = "Mesher command is currently not supported.";
    }
    else if (props.task.taskType === "Hybrid") {
        command = `> python3 src/standalone_hybridization.py \\\n\
        --dg_outputfile ${props.urls["dg Result JSON"]} \\\n\
        --ga_outputfile ${props.urls["ga Result JSON"]} \\\n\
        --dg_wavpath /tmp/${props.task.id}/dg_result_zip_content/ \\\n\
        --ga_wavpath /tmp/${props.task.id}/ga_result_zip_content/ \\\n\
        --outputDir /tmp/${props.task.id}`;
    }

    return (
        <div>
            <h3>Task run command for local execution</h3>
            <p>Note: You may have to substitute some directories, for Hybridizer you must unzip solver result zip files.</p>
            <pre style={{ maxWidth: "1000px" }}>{command}</pre>
        </div>
    )
}

/* Fetch all urls related to task. */
export const TaskUrls = (props) => {
    const [currentTaskId, setCurrentTaskId] = React.useState(null);
    const {
        loading,
        error,
        refresh,
        data: urls,
    } = useApi(`https://api.treble.tech/api/Debug/tasks/${props.task.id}/urls`);

    if (currentTaskId === null) {
        setCurrentTaskId(props.task.id);
    } else if (currentTaskId !== props.task.id) {
        setCurrentTaskId(props.task.id);
        refresh();
    }

    const columns = [
        { name: "Data name", wrap: true, selector: row => row[0] },
        { name: "Url", wrap: true, selector: row => row[1] },
        {
            cell: row => <button onClick={() => navigator.clipboard.writeText(row[1])}>Copy url</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        }
    ];

    if (loading) {
        return <div>Loading urls...</div>;
    }

    return (
        <div>
            <DataTable
                columns={columns}
                data={Object.entries(urls)}
                progressPending={loading}
                dense
                direction="ltr"
                fixedHeaderScrollHeight="300px"
                highlightOnHover
                pagination
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                paginationPerPage="25"
            />
            <TaskRunCommand
                task={props.task}
                urls={urls}
            />
        </div>
    )
}

/* List of tasks */
class TaskList extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            { name: "Id", grow: 2, wrap: true, selector: row => row.id },
            { name: "CreatedAt", selector: row => row.createdAt, sortable: true },
            { name: "Project", selector: row => row.projectName, compact: true },
            { name: "Model", selector: row => row.modelName, compact: true },
            { name: "Simulation", selector: row => row.simulationName, compact: true },
            { name: "Type", selector: row => row.taskType, compact: true },
            { name: "Status", selector: row => row.status, compact: true },
            {
                name: "%",
                selector: row => row.progressPercentage,
                sortable: true,
                cell: row => row.progressPercentage, compact: true
            },
            { name: "Message", selector: row => row.userStatusMsg },
            {
                cell: row => <button onClick={() => props.handleDetailsClick(row)}>⋮</button>,
                ignoreRowClick: true,
                allowOverflow: true,
                button: false,
                width: '40px'
            },
            {
                cell: row => { return row.completedAt === null && props.handleCancelClick === null && <button onClick={() => props.handleCancelClick(row.id)}>Cancel</button> },
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
                omit: row => (row.completedAt !== null || props.handleCancelClick !== null)
            },
        ];
    }

    render() {
        return (
            <DataTable
                columns={this.columns}
                data={this.props.tasks}
                progressPending={this.props.pending}
                dense
                direction="ltr"
                fixedHeaderScrollHeight="300px"
                highlightOnHover
                pagination
                responsive
                subHeaderAlign="right"
                subHeaderWrap
                paginationPerPage="25"
            />
        )
    }
}

/* Get and list all tasks in task group */
export const TasksInGroup = (props) => {
    const {
        loading,
        error,
        refresh,
        data: tasks,
    } = useApi(`https://api.treble.tech/api/Debug/tasks/?groupId=${props.groupId}`);

    if (loading) {
        return <div>Loading urls...</div>;
    }

    return (
        <TaskList
            tasks={tasks}
            pending={loading}
            handleDetailsClick={props.handleDetailsClick}
        />
    );
}

export const CustomTaskDetails = ({ taskId, closeModalParent, handleDetailsClickParent }) => {
    const [selectedTask, setSelectedTask] = React.useState(taskId);

    React.useEffect(() => {
        setSelectedTask(taskId);
    }, [taskId]);

    const {
        loading,
        error,
        refresh,
        data: task,
    } = useApi(`https://api.treble.tech/api/Debug/tasks/${taskId}`);

    if (taskId === '') {
        return <div>TaskId empty</div>;
    }

    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        return <div>Oops</div>;
    }

    function closeModal() {
        setSelectedTask(null);
        closeModalParent();
    }

    function handleDetailsClick(task) {
        handleDetailsClickParent(task);
        setSelectedTask(null);
    }

    return (
        <div>
            {task && selectedTask &&
                <TaskModal
                    closeModal={closeModal}
                    selectedTask={task}
                    selectedGroupId={task.taskGroupId}
                    handleDetailsClick={t => handleDetailsClick(t)}
                />
            }
        </div>
    );
}

export const TaskApp = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const userRoles = user ? user['https://treble.tech/roles'] : [];
    const [selectedTask, setSelectedTask] = React.useState(null);
    const [selectedGroupId, setSelectedGroupId] = React.useState(null);
    const [customTaskId, setCustomTaskId] = React.useState('');
    const [customTaskDetails, fetchCustomTaskDetails] = React.useState(false);

    let fromDate = Moment().subtract(60, 'days').format('YYYY-MM-DDT00:00:00')
    const {
        loading,
        error,
        refresh,
        data: tasks
    } = useApi(`https://api.treble.tech/api/Debug/tasks?from=${fromDate}`);

    function handleDetailsClick(task) {
        setSelectedTask(task);
        setSelectedGroupId(task.taskGroupId);
    };

    function handleCancelClick(taskId) {
        console.log("Cancel clicked");
        console.log(taskId);
    }

    function closeModal() {
        setSelectedTask(null);
        setSelectedGroupId(null);
        setCustomTaskId('');
        fetchCustomTaskDetails(false);
    }

    function handleGetTaskClick() {
        fetchCustomTaskDetails(true);
    }

    if (!isAuthenticated) {
        return (<h2>Please log in</h2>);
    }
    //const 
    /*const getTokenAndTryAgain = async () => {
          await getAccessTokenWithPopup(opts);
          refresh();
      };*/
    if (loading) {
        return <div>Loading...</div>;
    }
    if (error) {
        /*if (error.error === 'login_required') {
            return <button onClick={() => login(opts)}>Login</button>;
        }
        if (error.error === 'consent_required') {
            return (
                <button onClick={getTokenAndTryAgain}>Consent to reading users</button>
            );
        }*/
        return <div>Oops {error.message}</div>;
    }
    return (
        <div>
            <TaskModal
                closeModal={closeModal}
                selectedTask={selectedTask}
                selectedGroupId={selectedGroupId}
                handleDetailsClick={handleDetailsClick}
            />
            {userRoles.includes('Superuser') && userRoles.includes('DataAccess') && (
                <div>
                    <input
                        id='task'
                        type='text'
                        placeholder='TaskId'
                        value={customTaskId}
                        onChange={event => setCustomTaskId(event.target.value)}
                        style={{
                            marginTop: '20px',
                            marginBottom: '20px',
                        }}
                    />
                    <button onClick={handleGetTaskClick}>Get task details</button>
                    {customTaskDetails &&
                        <CustomTaskDetails
                            taskId={customTaskId}
                            closeModalParent={closeModal}
                            handleDetailsClickParent={handleDetailsClick}
                        />
                    }
                </div>
            )}
            <TaskList
                tasks={tasks}
                pending={loading}
                handleDetailsClick={handleDetailsClick}
                handleCancelClick={handleCancelClick}
            />
        </div>
    );
};

export const TaskModal = ({
    closeModal,
    selectedTask,
    selectedGroupId,
    handleDetailsClick,
}) => {
    let subtitle;
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    return (
        <Modal
            isOpen={selectedTask != null}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={modalStyles}
            contentLabel="Example Modal"
        >
            <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Task details</h2>
            <button onClick={closeModal}>close</button>
            {selectedTask !== null &&
                <div>
                    <div>
                        <pre wrap="on">{JSON.stringify(selectedTask, null, 2)}</pre>
                    </div>
                    <LogFetcher taskId={selectedTask.id} />
                    <h3>Task data</h3>
                    <TaskUrls task={selectedTask} />
                    <h3>Related tasks</h3>
                    {selectedGroupId !== null &&
                        <TasksInGroup
                            groupId={selectedGroupId}
                            handleDetailsClick={handleDetailsClick}
                        />
                    }
                </div>
            }
        </Modal>
    );
}
