import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';

import './index.css';
import { TaskApp } from './TaskApp'
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

import { LoginButton, LogoutButton } from "./auth.js"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Auth0Provider
    domain="https://treble-prod.eu.auth0.com"
    clientId="d2t96DOA7UkXl4OnvEJWgIliUTKmHkZl"
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
  >
    <LoginButton />
    <LogoutButton />
    <TaskApp />
    
  </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
